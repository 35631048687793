import '/src/app.css'
import Swiper, { Navigation, Pagination, Navigation, Autoplay } from "swiper";
import Rellax from 'rellax'
import GLightbox from 'glightbox';

document.addEventListener("DOMContentLoaded", function() {

  // setup rellax
  const rellaxin = document.querySelectorAll(".rellax");
  rellaxin.forEach(el => {
    const rellax = new Rellax(el, {
      relativeToWrapper: true,
      wrapper: el.parentElement
    });
    window.addEventListener("scroll", () => { // fix to init
      rellax.refresh();
    });
  });

  const lightbox = GLightbox({
    loop: false,
    autoplayVideos: true
  });

    // swiper usa logos
    const usaLogoSwiper = new Swiper('.usaLogoSwiper', {
      modules: [Navigation, Pagination, Autoplay],
      loop: true,
      autoplay: {
        delay: 1,
        disableOnInteraction: false
      },
      direction: 'horizontal',
      speed: 4000,
      slidesPerView: 'auto',
      // Optional parameters
      // breakpoints: {
      //   // when window width is >= 320px
      //   480: {
      //     slidesPerView: 4,
      //     spaceBetween: 20
      //   },
      //   720: {
      //     slidesPerView: 6,
      //     spaceBetween: 20
      //   },
      //   1320: {
      //     speed: 2000,
      //     slidesPerView: 10,
      //     spaceBetween: 40
      //   },
      //   1024: {
      //     speed: 2000,
      //     slidesPerView: 8,
      //     spaceBetween: 40
      //   }
      // }

    });


    const caseSwiper = new Swiper('.caseSwiper', {
      modules: [Navigation, Pagination, Autoplay],
      loop: true,
      // autoplay: {
      //   delay: 1,
      //   disableOnInteraction: false
      // },
      speed: 1000,
      slidesPerView: 1,
      // Optional parameters
      direction: 'horizontal',
      breakpoints: {
        // when window width is >= 320px
        480: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
          spaceBetween: 40
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
        // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-new-next',
        prevEl: '.swiper-button-new-prev',
      },

    });


  fitty('.firstname');
  fitty('.surname');
  fitty('.talentGridName');

  // var slideUp = {
  //   delay: 0,
  //   distance: '-150px',
  //   duration: 600,
  //   interval: 50,
  //   opacity: 0,
  //   reset: false,
  //   afterReveal: function(el) {
  //     ScrollReveal().clean(el);
  //   }
  // };

  const swiper = new Swiper('.mainswiper', {
    modules: [Navigation, Pagination, Navigation],

    // Optional parameters
    direction: 'horizontal',
    loop: true,

    // Navigation arrows
    navigation: {
      nextEl: 'div.button-next',
      prevEl: 'div.button-prev',
    },
  });


  const talentswiper = new Swiper('.talentSwiper', {
    modules: [Navigation, Pagination],

    // Optional parameters
    direction: 'horizontal',
    loop: false,

    // Navigation arrows
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
  });



  // talentswiper.on('slideChange', function () {
  //   ScrollReveal().clean('.slider-text');
  // });

  ScrollReveal().reveal('.office-animation', {
    delay: 150,
    distance: '100px',
    duration: 1000,
    interval: 150,
    reset: false
  });

  ScrollReveal().reveal('.talent-gallery img', {
    delay: 0,
    distance: '-150px',
    duration: 600,
    interval: 50,
    opacity: 0,
    reset: false
  });

  ScrollReveal().reveal('.talent-card', {
    delay: 0,
    distance: '-150px',
    duration: 600,
    interval: 50,
    opacity: 0,
    reset: false
  });

  ScrollReveal().reveal('.talent-gallery h2', {
    delay: 0,
    distance: '-150px',
    duration: 600,
    interval: 50,
    opacity: 0,
    reset: false
  });


  ScrollReveal().reveal('.slide-up', {
    delay: 50,
    distance: '150px',
    duration: 1200,
    interval: 0,
    opacity: 0,
    reset: false
  });

  ScrollReveal().reveal('.slide-up-interval', {
    delay: 50,
    distance: '150px',
    duration: 1200,
    interval: 50,
    opacity: 0,
    reset: false
  });

  ScrollReveal().reveal('.slide-right', {
    delay: 250,
    origin: 'right',
    distance: '150px',
    duration: 600,
    interval: 50,
    opacity: 0,
    reset: false
  });
  ScrollReveal().reveal('.slide-right-prod', {
    delay: 250,
    origin: 'right',
    distance: '150px',
    duration: 600,
    interval: 50,
    opacity: 0,
    reset: true
  });
  ScrollReveal().reveal('.slide-left', {
    delay: 150,
    origin: 'left',
    distance: '150px',
    duration: 600,
    interval: 50,
    opacity: 0,
    reset: false
  });



});

